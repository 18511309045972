<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">Ledger:</label>
        <div class="col">
          <select  id="cmbldgr" class="form-control" v-model="ldgr" >
            <option value="0">-</option>
            <option v-for="ledger in ledgers" v-bind:value="ledger.code">
              {{ ledger.name }}
            </option>
          </select>
        </div>

        <label class="col-md-1 col-form-label">From:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="From Date"  v-model="from_date" >
        </div>

        <label class="col-md-1 col-form-label">Upto:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="Upto Date" v-model="upto_date" >
        </div>
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData">Show</button>
      </div>

    </div>

    <div class="table-responsive">
      <table  id="daybook-table" class="table table-xs table-columned table-hover" data-target=".context-table">
        <thead>
        <tr>
          <th style="width: 50px;">S.No</th>
          <th style="width: 110px;">Date</th>
          <th>Description</th>
          <th style="width: 100px;">Vch Type</th>
          <th style="width: 100px;">Vch No</th>
          <th style="width: 150px;text-align: right">Debit</th>
          <th style="width: 150px;text-align: right">Credit</th>
        </tr>
        </thead>
        <tbody v-if="trans"  >

            <tr scope="row"  v-for="(trn,index) in trans">
              <td>{{index + 1}}</td>
              <td> {{trn.doc_date | formatDate}} </td>
              <td> {{trn.details[0].ledger.name}} </td>
              <td> {{trn.vchtype}} </td>
              <td> {{trn.doc_no}} </td>
              <td style="width: 150px;text-align: right"> </td>
              <td style="width: 150px;text-align: right"> </td>
            </tr>

        </tbody>
      </table>
    </div>

    <!-- Clickable menu -->
    <!--<ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">-->
      <!--<li>-->
        <!--<a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilter" >-->
          <!--<i class="fab-icon-open icon-filter3"></i>-->
          <!--<i class="fab-icon-close icon-filter4"></i>-->
        <!--</a>-->
      <!--</li>-->
    <!--</ul>-->
    <!-- /clickable menu -->

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->

      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="225" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline" style="background-color: darkgrey;color: white;padding: 10px;">
            <h3 class="card-title">Filter Request</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-9">
                <input type="date" class="form-control"  v-model="from_date" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">To:</label>
              <div class="col-md-9">
                <input type="date" class="form-control" v-model="upto_date" />
              </div>
            </div>


            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Filter<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import DayBookRow from '@/components/core/DayBookRow.vue'
  export default {
    name: 'DayBookView',
    store,
    components:{
      DayBookRow
    },
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        mytable: {},
        trans: [],
        ldgr:0,
        ledgers:[],
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
      }
    },
    created () {

    },
    mounted () {
      let self = this;


      self.$data.mytable = $('#daybook-table').children('tbody');


      $('#daybook-table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Refresh') {
            self.loadData();
          }
        }
      });

      $('#txtsearch').keyup(function () {

        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $('#daybook-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide();

      });

      self.loadLedgers();

    },
    filters: {
      formatDate (val) {
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {
        if (value !== 0)
          return value.toFixed(2);
        else
          return '';
      },
    },
    methods:{
      showFilter(){
        this.$modal.show('filter-window');
      },
      loadLedgers () {
        let self = this

        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        }


        //fetch the Ledgers
        self.$data.ledgers = []
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {
            if (_.isArray(resp.data)) {
              self.$data.ledgers = resp.data
            }
          } else {
            swal({ title: 'Oops', text: resp.message, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        });
      },
      loadData(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.trans = [];
        $('#daybook-table tbody tr').remove();
        // self.$data.mytable.remove();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/daybook/${store.state.user.finyear}/?ibr=${self.$data.current_ibr}&ldgr=${self.$data.ldgr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray (resp.data)) {
              // self.$data.trans = resp.data;

              resp.data.forEach(function (trn,idx) {

                trn.details.forEach(function (detail) {
                  if (detail.idx == 0 ) {
                    var row = `<tr>
                              <td>${idx + 1}</td>
                              <td>${dateFormatter(trn.doc_date)}</td>
                              <td>${detail.ledger.name}</td>
                              <td>${accountingVoucherTypeFormatter(trn.vchtype)}</td>
                              <td>${trn.doc_no}</td>
                              <td style="width: 150px;text-align: right">${indianFormat(detail.cr)}</td>
                              <td style="width: 150px;text-align: right">${indianFormat(detail.dr)}</td>
                          </tr>`;
                    self.$data.mytable.append(row);
                  }
                });

                trn.details.forEach(function (detail) {
                  if (detail.idx > 0 ) {
                    var row = `<tr>
                              <td></td>
                              <td></td>
                              <td>${detail.ledger.name}</td>
                              <td></td>
                              <td></td>
                              <td style="width: 150px;text-align: right">${indianFormat(detail.cr)}</td>
                              <td style="width: 150px;text-align: right">${indianFormat(detail.dr)}</td>
                          </tr>`;
                    self.$data.mytable.append(row);
                  }
                });

                self.$data.mytable.append('<tr style="background-color: lightgrey;" ><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>');

              });
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          self.$modal.hide('filter-window');
          $(self.$data.mytable).unblock();
        });

      }
    }
  }

</script>

<style scoped>

</style>
