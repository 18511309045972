<template>
  <tr v-if="trans" >
    <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>
    <td style="padding: 0px;text-align: center;"> {{trans.doc_date }} </td>
    <td> {{detail.ledger.name}} </td>
    <td> {{trans.vchtype}} </td>
    <td> {{trans.doc_no}} </td>
    <td style="width: 150px;text-align: right"> </td>
    <td style="width: 150px;text-align: right"> </td>
  </tr>
</template>

<script>
  export default {
    name: 'DayBookRow',
    props:{
      trans:{
        type:Object
      },
      index: {
        type: Number,
        default: () => 0
      },
    }
  }
</script>

<style scoped>

</style>
